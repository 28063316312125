import * as React from "react"
import Seo from "../../components/seo"
import { RichText } from 'prismic-reactjs'
import { Link, graphql } from "gatsby"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Copyright from "../../components/copyright"
import { GatsbyImage } from "gatsby-plugin-image"

const BPIRPage = ({data}) => {
  const entry = data.prismicBpirPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname="/privacy/" />
      <Header />
      <div className="w-full pt-12 pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 items-start justify-between">
            <div className="w-full sm:w-6/12">
              <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight mb-12">
                <h1>{entry.data.heading.text}</h1>
              </div>
              <div className="font-ubuntu prose prose-xl leading-normal">
                {RichText.render(entry.data.text.richText)}
              </div>
              <div className="mt-12 inline-block">
                <div className="font-ubuntu text-2xl text-red border-2 border-red py-2 px-4 inline">
                  <Link to="/contact/">Make enquiry <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-6/12 mt-12 sm:mt-0">
              <GatsbyImage image={entry.data.image.gatsbyImageData} alt={entry.data.heading.text} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "contain" }} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Copyright />
    </>
  )
}

export const query = graphql`
query {
  prismicBpirPage {
    data {
      text {
        richText
      }
      page_title
      meta_description
      heading {
        text
      }
      image {
        gatsbyImageData(width: 1024, height: 1024)
      }
    }
  }
}
`

export default BPIRPage
